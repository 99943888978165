
import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
import BaseSelect from '@/components/common/base-select/base-select'
import { getDictLists } from '@/filters/fromDict'
// import { validbusinessNo } from '@/utils/validate'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessCode',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      attrs: {
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: SupplierSelect,
      isHidden: content.role === '22'
    },
    {
      label: '签约状态',
      prop: 'status',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.signStatusOptions,
        selectedField: ['dictId', 'dictName'],
        clearable: true
      },
      isHidden: content.activeNames === '2'
    },
    {
      label: '确认状态',
      prop: 'supplementConfirm',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: getDictLists('SUPPLEMENT_CONFIRM'),
        selectedField: ['dictId', 'dictName']
      },
      isHidden: content.activeNames !== '2'
    }
  ]
}
// 签约文件表格table
export const dataTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessCode'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      'show-overflow-tooltip': true,
      isHidden: context.role === '22'
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0')
      }
    },
    {
      label: '签约任务发起时间',
      prop: 'addTime',
      'show-overflow-tooltip': true
    },
    {
      label: '签约任务发起方式',
      prop: 'signType',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.signType === '1' ? '用户自行发起' : '流程发起')
      }
    },
    {
      label: '签约有效文件数/签约文件数',
      prop: 'contractCount',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.contractCount ? row.validFileCount + '/' + row.contractCount : '--')
      }
    },
    {
      label: '签约方式',
      prop: 'suppliersSealType',
      formatter: row => {
        const [obj] = context.suppliersSealType.filter(item => item.dictId === row.suppliersSealType.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '签约状态',
      prop: 'status',
      formatter: row => {
        const [obj] = context.signStatusOptions.filter(item => item.dictId === row.status.toString())
        return obj ? obj.dictName : '--'
      }
    },
    {
      label: '待确认文件数',
      prop: '',
      isHidden: true
    },
    {
      label: '确认状态',
      prop: '',
      isHidden: true
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}

// 补签文件表格table
export const repairTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessCode'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      'show-overflow-tooltip': true,
      isHidden: context.role === '22'
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0')
      }
    },
    {
      label: '待确认文件数',
      prop: 'contractCount',
      'show-overflow-tooltip': true
    },
    {
      label: '签约状态',
      prop: 'status',
      formatter: row => {
        const [obj] = context.signStatusOptions.filter(item => item.dictId === row.status.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '是否超时',
      prop: 'expirationFlag',
      formatter: row => {
        return row.expirationFlag ? '是' : '否'
      }
    },
    {
      label: '确认状态',
      prop: 'supplementConfirm',
      formatter: row => {
        if (row.expirationFlag) {
          return row.supplementConfirm ? '已确认' : '待确认'
        } else {
          return '-'
        }
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
